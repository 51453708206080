import React, { useCallback, useEffect, useRef, useState } from "react"
import { AspectRatio, Box, Image, useBreakpointValue } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import ResolvedImage from "@app/components/ResolvedImage"
import Icon from "@app/components/Icon"
import MotionBox from "@app/components/MotionBox"

import Berry4 from "@static/images/FF_3D-BERRY-4.png"
import Berry3 from "@static/images/FF_3D-BERRY-3.png"
import Berry6 from "@static/images/FF_3D-BERRY-6.png"
import Berry2 from "@static/images/FF_3D-BERRY-2.png"
import Berry5 from "@static/images/FF_3D-BERRY-5.png"
import WaterMelon1 from "@static/images/FF_3D-WATERMELON-1.png"
import WaterMelon2 from "@static/images/FF_3D-WATERMELON-2.png"
import Gummy1 from "@static/images/FF_3D-GUMMY-1.png"
import Gummy2 from "@static/images/FF_3D-GUMMY-2.png"
import IceCream from "@static/images/FF_3D-ICECREAM-PINK.png"
import GoodYearSticker from "@static/images/GY_STICKER.png"
import GoodYearText from "@static/images/GY_TEXT.png"
import GoodYearEllipse from "@static/images/GY_ELLIPSE.png"
import GoodYearStar from "@static/images/GY_STAR.png"

import XOXO_YELLOW from "@static/images/XOXO_YELLOW.png"
import XOXO_PINK from "@static/images/XOXO_PINK.png"
import XOXO_BLUE from "@static/images/XOXO_BLUE.png"
import WORTHIT_PINK from "@static/images/WORTHIT_PINK.png"
import WORTHIT_GREEN from "@static/images/WORTHIT_GREEN.png"
import WORTHIT_BLUE from "@static/images/WORTHIT_BLUE.png"
import STAYTRUE_YELLOW from "@static/images/STAYTRUE_YELLOW.png"
import STAYTRUE_GREEN from "@static/images/STAYTRUE_GREEN.png"
import SOSWEETPINK from "@static/images/SOSWEETPINK.png"
import SOSWEET_GREEN from "@static/images/SOSWEET_GREEN.png"
import SOSWEET_BLUE from "@static/images/SOSWEET_BLUE.png"
import SELFLOVEALWAYS_PINK from "@static/images/SELFLOVEALWAYS_PINK.png"
import SELFLOVEALWAYS_GREEN from "@static/images/SELFLOVEALWAYS_GREEN.png"
import SELFLOVEALWAYS_BLUE from "@static/images/SELFLOVEALWAYS_BLUE.png"
import LOVEYOU_YELLOW from "@static/images/LOVEYOU_YELLOW.png"
import LOVEYOU_PINK from "@static/images/LOVEYOU_PINK.png"
import HEYCUTIE_PINK from "@static/images/HEYCUTIE_PINK.png"
import HEYCUTIE_BLUE from "@static/images/HEYCUTIE_BLUE.png"
import DREAMBIG_YELLOW from "@static/images/DREAMBIG_YELLOW.png"
import DREAMBIG_GREEN from "@static/images/LOVEYOU_YELLOW.png"
import BETTERIRL_YELLOW from "@static/images/BETTERIRL_YELLOW.png"
import BETTERIRL_PINK from "@static/images/BETTERIRL_PINK.png"
import BETTERIRL_GREEN from "@static/images/BETTERIRL_GREEN.png"
import BETTERIRL_BLUE from "@static/images/BETTERIRL_BLUE.png"
import candyheartsIcon from "@static/images/candyhearts.png"

import VC_SM_LEFT from "@static/images/vc_sm_left.png"
import VC_SM_RIGHT from "@static/images/vc_sm_right.png"
import VC_LEFT from "@static/images/vc_left.png"
import VC_RIGHT from "@static/images/vc_right.png"
import VC_TEXT from "@static/images/violetCrumbelText.png"

import BC_TEXT from "@static/images/BC_TEXT.svg"
import BC_BOWL from "@static/images/BC_BOWL.png"
import BC_SPLASH from "@static/images/BC_SPLASH.svg"

import FAIRY_BREAD_LOGO from "@static/images/FAIRY_BREAD_LOGO.svg"
import FAIR_BREAD_SPRINKLES from "@static/images/FAIRY_BREAD_SPRINKLES.png"

import TROPICO_LOGO from "@static/images/TROPICO_LOGO.svg"
import TROPICO_RAIN_DROPS from "@static/images/TROPICO_RAIN_DROPS.png"

import Chupa_Chups_Cola from "@static/images/Chupa_Chups_Cola.png"
import Chupa_Chups_Lemon from "@static/images/Chupa_Chups_Lemon.png"
import Chupa_Chups_Logo from "@static/images/Chupa_Chups_Logo.svg"
import Chupa_Chups_Raspberry from "@static/images/Chupa_Chups_Raspberry.png"

import MALLOW from "@static/images/MALLOW.svg"
import MALLOWS_FALLING from "@static/images/MALLOWS_FALLING.png"

import { useMedia } from "@app/hooks/useMedia"

type Props = {
  item: any
  handleLinkClick?: () => void
}

const NavigationHeaderMegaLevel2Featured: React.FC<Props> = ({ item, handleLinkClick }) => {
  const { anyUrlResolver } = useRoutes()
  const [isSelected, setIsSelected] = useState(false)
  // State for handling out of hover animations
  const [wasSelected, setWasSelected] = useState(false)

  useEffect(() => {
    if (isSelected) {
      setWasSelected(true)
    }
  }, [isSelected])

  const resolvedCardLink = anyUrlResolver(item?.link)

  const handleSelection = () => {
    setIsSelected(prev => !prev)
  }

  return (
    <Box
      position="relative"
      width="100%"
      maxW="600px"
      marginBottom={{
        lg: 4,
      }}
      onMouseEnter={handleSelection}
      onFocus={handleSelection}
      onBlur={handleSelection}
      onMouseLeave={handleSelection}
    >
      <Link to={resolvedCardLink?.url} onClick={handleLinkClick} textDecoration="none !important" _focus={{ boxShadow: "none !important" }}>
        <Box width="100%" position="relative" borderRadius="8px" overflow="hidden">
          <AspectRatio ratio={432 / 286}>
            <ResolvedImage image={item?.image} borderRadius="8px" />
          </AspectRatio>

          {/* Hover Animation State FRUITY FLOSS */}
          {item.featureType === "fruityFloss" && <FruitFlossHoverAnimation isSelected={isSelected} />}

          {/* Hover Animation State COSMIC CHOC */}
          {item.featureType === "cosmicChoc" && <CosmicChocHoverAnimation isSelected={isSelected} />}

          {/* Hover Animation State CANDY HEARTS */}
          {item.featureType === "candyHearts" && <CandyHeartsAnimation isSelected={isSelected} />}
        </Box>
      </Link>
      {item.featureType === "cosmicChoc" && (
        <MotionBox
          position="absolute"
          left={0}
          bottom={0}
          w="40%"
          zIndex={102}
          cursor={"pointer"}
          animate={
            isSelected
              ? {
                  transform: "scale(1.1) translateX(-15%) translateY(10%)",
                  transition: { type: "spring", stiffness: 100, damping: 8, mass: 10 },
                }
              : {}
          }
          initial={{ transform: "scale(1) translateX(-20%) translateY(15%)" }}
        >
          <Icon name="cosmicChoc" zIndex={102} w="100%" />
        </MotionBox>
      )}
      <Link to={resolvedCardLink?.url} onClick={handleLinkClick} textDecoration="none !important" _focus={{ boxShadow: "none !important" }}>
        {item.featureType === "snoozeville" && (
          <MotionBox
            position="absolute"
            left={"17%"}
            bottom={0}
            w="66%"
            zIndex={102}
            cursor={"pointer"}
            animate={
              isSelected
                ? {
                    transform: "scaleX(1.05) scaleY(1.25) translateY(20%)",
                    transition: { type: "spring", stiffness: 70, damping: 8, mass: 2 },
                  }
                : wasSelected
                  ? {
                      transform: "scaleX(1) scaleY(1) translateY(35%)",
                      transition: {
                        type: "spring",
                        stiffness: 70,
                        damping: 8,
                        mass: 2,
                        ease: [0.175, 0.885, 0.32, 1.275], // Use a bounce easing function
                      },
                    }
                  : {}
            }
            initial={{ transform: "scaleX(1) scaleY(1) translateY(35%)" }}
          >
            <Icon name="snoozeville" zIndex={102} w="100%" />
          </MotionBox>
        )}
        {item.featureType === "chupaChups" && <ChupaChupsV2HoverIcon isSelected={isSelected} />}
        {item.featureType === "goodYear" && <GoodYearHoverIcon isSelected={isSelected} />}
        {item.featureType === "violetCrumble" && <VioletCrumbleHoverIcon isSelected={isSelected} />}
        {item.featureType === "candyHearts" && (
          <Box position="absolute" bottom="-50px" left="50%" transform="translate(-50%)" zIndex={102}>
            <Image src={candyheartsIcon} />
          </Box>
        )}
        {item.featureType === "breakfastClub" && <BreakfastClubHoverIcon isSelected={isSelected} />}
        {item.featureType === "fairyBread" && <FairyBreadHoverIcon isSelected={isSelected} />}
        {item.featureType === "tropicalCoconut" && <TropicalCoconutHoverIcon isSelected={isSelected} />}
        {item.featureType === "mallow" && <MallowHoverIcon isSelected={isSelected} />}
      </Link>
    </Box>
  )
}

const CandyHeartImages = [
  XOXO_YELLOW,
  XOXO_PINK,
  XOXO_BLUE,
  WORTHIT_PINK,
  WORTHIT_GREEN,
  WORTHIT_BLUE,
  STAYTRUE_YELLOW,
  STAYTRUE_GREEN,
  SOSWEETPINK,
  SOSWEET_GREEN,
  SOSWEET_BLUE,
  SELFLOVEALWAYS_PINK,
  SELFLOVEALWAYS_GREEN,
  SELFLOVEALWAYS_BLUE,
  LOVEYOU_YELLOW,
  LOVEYOU_PINK,
  HEYCUTIE_PINK,
  HEYCUTIE_BLUE,
  DREAMBIG_YELLOW,
  DREAMBIG_GREEN,
  BETTERIRL_YELLOW,
  BETTERIRL_PINK,
  BETTERIRL_GREEN,
  BETTERIRL_BLUE,
]

const CandyHeartsAnimation: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const boxProperties = CandyHeartImages.map((_, index) => ({
    speed: (Math.random() * 0.8 + 0.5) * 2,
    rotation: Math.random() * 360,
    startTop: Math.random() * -110 - 100,
    startLeft: Math.random() * 120 - 10,
    image: CandyHeartImages[index],
  }))

  return (
    <Box overflow="hidden">
      {boxProperties.map((prop, index) => (
        <MotionBox
          key={index}
          position="absolute"
          zIndex={1 + index}
          w="108px"
          h="108px"
          top={`${prop.startTop}px`}
          left={`${prop.startLeft}%`}
          initial={{ top: `${prop.startTop}px`, left: `${prop.startLeft}%` }}
          animate={
            isSelected
              ? {
                  y: "550%",
                  rotate: prop.rotation,
                  transition: { duration: prop.speed, ease: "linear" },
                }
              : {
                  y: -50,
                  rotate: 0,
                  transition: { duration: prop.speed, ease: "easeOut" },
                  opacity: 0,
                }
          }
        >
          <Image src={prop.image} w="100%" h="100%" />
        </MotionBox>
      ))}
    </Box>
  )
}

const FruitFlossHoverAnimation: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  return (
    <>
      <MotionBox
        position="absolute"
        zIndex={99}
        w="16%"
        animate={
          isSelected
            ? {
                transform: "scale(0.95) rotate(-5deg)",
                bottom: "13%",
                left: "84%",
                transition: { duration: 0.3, ease: "easeInOut" },
              }
            : {
                transform: "scale(1) rotate(0deg)",
                bottom: "15%",
                left: "82%",
                transition: { duration: 0.3 },
              }
        }
        initial={{ left: "82%", bottom: "15%", transform: "scale(1) rotate(0deg)" }}
      >
        <Image src={Berry2} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        zIndex={103}
        w="13%"
        animate={
          isSelected
            ? {
                left: "53%",
                bottom: "15%",
                transform: "rotate(-10deg)",
                transition: { duration: 0.3, ease: "easeInOut" },
              }
            : {
                left: "54%",
                bottom: "16%",
                transform: "rotate(0deg)",
                transition: { duration: 0.3 },
              }
        }
        initial={{ left: "54%", bottom: "16%", transform: "rotate(0deg)" }}
      >
        <Image src={Berry3} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        zIndex={106}
        w="21%"
        animate={
          isSelected
            ? {
                transition: { duration: 0.3, ease: "easeInOut" },
                bottom: "16%",
                left: "66%",
              }
            : {
                transition: { duration: 0.3 },
                bottom: "46%",
                left: "63%",
              }
        }
        initial={{ left: "63%", bottom: "46%" }}
      >
        <Image src={Berry4} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        zIndex={105}
        w="15%"
        animate={
          isSelected
            ? {
                transition: { duration: 0.3, ease: "easeInOut" },
                left: "50%",
                bottom: "-3%",
              }
            : {
                transition: { duration: 0.3 },
                left: "50%",
                bottom: "0%",
              }
        }
        initial={{ left: "50%", bottom: "0%" }}
      >
        <Image src={Berry5} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        zIndex={102}
        w="13%"
        animate={
          isSelected
            ? {
                transition: { duration: 0.3, ease: "easeInOut" },
                bottom: "-3%",
                left: "67%",
                transform: "rotate(10deg)",
              }
            : {
                transition: { duration: 0.3 },
                bottom: "0%",
                left: "66%",
                transform: "rotate(0deg)",
              }
        }
        initial={{ bottom: "0%", left: "66%", transform: "rotate(0deg)" }}
      >
        <Image src={Berry6} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        left={"40%"}
        bottom={"10%"}
        zIndex={101}
        w="22%"
        animate={
          isSelected
            ? {
                transform: "rotate(10deg) scale(0.95)",
                transition: { duration: 0.3, ease: "easeInOut" },
              }
            : {
                transform: "rotate(0deg) scale(1)",
                transition: { duration: 0.3 },
              }
        }
        initial={{ transform: "rotate(0deg) scale(1)" }}
      >
        <Image src={WaterMelon1} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        left={"63%"}
        bottom={"15%"}
        zIndex={102}
        w="15%"
        animate={
          isSelected
            ? {
                transform: "rotate(110deg)",
                transition: { duration: 0.3, ease: "easeInOut" },
              }
            : {
                transform: "rotate(0deg)",
                transition: { duration: 0.3 },
              }
        }
        initial={{ transform: "rotate(0deg)" }}
      >
        <Image src={WaterMelon2} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        zIndex={100}
        w="14%"
        animate={
          isSelected
            ? {
                transform: "scale(0.82) rotate(-5deg)",
                bottom: "7%",
                left: "75%",
                transition: { duration: 0.3, ease: "easeInOut" },
              }
            : {
                transform: "scale(1) rotate(0deg)",
                bottom: "10%",
                left: "73%",
                transition: { duration: 0.3 },
              }
        }
        initial={{ left: "73%", bottom: "10%", transform: "scale(1) rotate(0deg)" }}
      >
        <Image src={Gummy1} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        left={"55%"}
        bottom={"3%"}
        zIndex={103}
        w="13%"
        animate={
          isSelected
            ? {
                transition: { duration: 0.3, ease: "easeInOut" },
                bottom: "2%",
              }
            : {
                transition: { duration: 0.3 },
                bottom: "3%",
              }
        }
        initial={{ left: "55%", bottom: "3%" }}
      >
        <Image src={Gummy2} w="100%" />
      </MotionBox>

      <MotionBox
        position="absolute"
        left={"74%"}
        bottom={"0%"}
        zIndex={102}
        w="26%"
        animate={
          isSelected
            ? {
                transition: { duration: 0.3, ease: "easeInOut" },
                left: "74%",
                bottom: "-2%",
              }
            : {
                transition: { duration: 0.3 },
                left: "74%",
                bottom: "0%",
              }
        }
        initial={{ left: "74%", bottom: "0%" }}
      >
        <Image src={IceCream} w="100%" />
      </MotionBox>
    </>
  )
}

const CosmicChocHoverAnimation: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  return (
    <>
      <MotionBox
        position="absolute"
        zIndex={101}
        borderRadius="8px"
        top={0}
        w="100%"
        animate={
          isSelected
            ? {
                transform: "translateY(50%) scale(1, 2)",
                transition: { type: "spring", stiffness: 100, damping: 8, mass: 10 },
              }
            : {}
        }
        initial={{ transform: "translateY(0%) scale(1, 1)" }}
      >
        <Icon name="chocolateDrip" />
      </MotionBox>
    </>
  )
}

// const ChupaChupsHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
//   const [isAnimationActive, setIsAnimationActive] = useState(false)

//   // Animation Duration in seconds
//   const animationDuration = 1.7

//   useEffect(() => {
//     if (isSelected) {
//       setIsAnimationActive(true)

//       setTimeout(() => {
//         setIsAnimationActive(false)
//       }, animationDuration * 1000)
//     }
//   }, [isSelected])

//   return (
//     <>
//       <Icon
//         name="chupaChups"
//         position="absolute"
//         width="46%"
//         zIndex={10}
//         right={"-6.5%"}
//         bottom={"-12.5%"}
//         transform="scale(0.71)"
//         cursor="pointer"
//       />
//       {chupaChupsIconLayerColors.map((layerColor, animateIndex) => {
//         const initialTransform = `scale(${1 - animateIndex / 10})`
//         return (
//           <MotionBox
//             key={animateIndex}
//             position="absolute"
//             cursor="pointer"
//             zIndex={animateIndex + 1}
//             initial={{ transform: initialTransform }}
//             width="46%"
//             right={"-6.5%"}
//             bottom={"-12%"}
//             color={layerColor}
//             animate={
//               isAnimationActive
//                 ? {
//                     transform: [
//                       ...new Array(7)
//                         .fill(null)
//                         .map((_, keyframeIndex) => `scale(${Math.min(1, 1 - (animateIndex - keyframeIndex) / 10)})`),
//                       // Revert back to original state on the last keyframe
//                       initialTransform,
//                     ],
//                     transition: { duration: animationDuration, ease: "linear" },
//                   }
//                 : {}
//             }
//           >
//             <Icon name={"chupaChupsLayer"} w="100%" />
//           </MotionBox>
//         )
//       })}
//     </>
//   )
// }

const ChupaChupsV2HoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const transition = { duration: 0.3, ease: "easeOut" }
  return (
    <Box position="absolute" top="0" height="100%" marginX="auto" left="0" width="100%">
      <Box position="absolute" top="0" left="0" height="100%" width="100%" borderRadius="8px">
        <MotionBox
          position="absolute"
          bottom={{ md: "6px", lg: "14px" }}
          left={{ md: "0%", lg: "1%" }}
          zIndex={102}
          transformOrigin="center"
          initial={{ rotate: 0, scale: 1 }}
          animate={
            isSelected
              ? {
                  rotate: -14,
                  scale: 1.3,
                  translateY: -10,
                  transition: {
                    ...transition,
                  },
                }
              : {
                  rotate: 0,
                  scale: 1,
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={Chupa_Chups_Lemon} width={{ md: "59%", lg: "64%" }} maxWidth="180px" />
        </MotionBox>

        <MotionBox
          position="absolute"
          bottom="-12%"
          width="100%"
          initial={{ scale: 0.6 }}
          animate={
            isSelected
              ? {
                  scale: 0.65,
                  transition: {
                    ...transition,
                  },
                }
              : {
                  scale: 0.6,
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <svg viewBox={Chupa_Chups_Logo.viewBox} width="100%">
            <use xlinkHref={Chupa_Chups_Logo.url} />
          </svg>
        </MotionBox>

        <MotionBox
          position="absolute"
          bottom={{ sm: "64px", md: "83px", lg: "80px", xl: "85px" }}
          right={{ sm: "8%", md: "12%", lg: "14%", xl: "17%" }}
          zIndex={102}
          transformOrigin="bottom left"
          initial={{ rotate: 0, scale: 1 }}
          animate={
            isSelected
              ? {
                  rotate: 4,
                  scale: 1.1,
                  transition: {
                    ...transition,
                  },
                }
              : {
                  rotate: 0,
                  scale: 1,
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={Chupa_Chups_Cola} width={{ sm: "64%", md: "70%", lg: "75%", xl: "83%" }} maxWidth="180px" />
        </MotionBox>

        <MotionBox
          position="absolute"
          bottom={{ md: "5px", lg: "5px" }}
          right={{ md: "-4%", lg: "-2%" }}
          zIndex={102}
          transformOrigin="center"
          initial={{ rotate: 0, scale: 1 }}
          animate={
            isSelected
              ? {
                  rotate: 11,
                  scale: 1.3,
                  transition: {
                    ...transition,
                  },
                }
              : {
                  rotate: 0,
                  scale: 1,
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={Chupa_Chups_Raspberry} width={{ md: "82%", lg: "90%" }} maxWidth="112px" />
        </MotionBox>
      </Box>
    </Box>
  )
}

const GoodYearHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const ellipseRef = useRef<HTMLDivElement>(null)
  const star1Ref = useRef<HTMLImageElement>(null)
  const star2Ref = useRef<HTMLImageElement>(null)
  const animationRef = useRef<number | null>(null)
  const startingAngle = 115
  const startingAngleRadians = (startingAngle * Math.PI) / 180
  const angleRef = useRef(startingAngleRadians)

  const [stickerLoaded, setStickerLoaded] = useState(false)
  const [textLoaded, setTextLoaded] = useState(false)
  const [ellipseLoaded, setEllipseLoaded] = useState(false)
  const [starLoaded, setStarLoaded] = useState(false)

  const allImagesLoaded = stickerLoaded && textLoaded && ellipseLoaded && starLoaded

  const setCircleTopLeft = (angle: number, starRef: HTMLElement | null) => {
    if (!ellipseRef.current || !starRef) return

    const starWidth = -(starRef.offsetWidth * 0.9)
    const xRadius = (ellipseRef.current.offsetWidth - starWidth - starRef.offsetWidth) / 2
    const yRadius = (ellipseRef.current.offsetHeight - starWidth - starRef.offsetHeight) / 2

    // Consider border width in the center calculations
    const xCenter = ellipseRef.current.offsetLeft + xRadius + starWidth / 2
    const yCenter = ellipseRef.current.offsetTop + yRadius + starWidth / 2

    const x = xCenter + xRadius * Math.cos(angle)
    const y = yCenter + yRadius * Math.sin(angle)

    starRef.style.left = `${x}px`
    starRef.style.top = `${y}px`
  }

  const setStartingPosition = useCallback(() => {
    setCircleTopLeft(startingAngleRadians, star1Ref.current)
    setCircleTopLeft(startingAngleRadians + Math.PI, star2Ref.current)
  }, [startingAngleRadians])

  useEffect(() => {
    // Set starting position on mount & resize
    setStartingPosition()

    window.addEventListener("resize", setStartingPosition)
    return () => window.removeEventListener("resize", setStartingPosition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Change direction based on isSelected
    // Adjust the speed for animation by editing the multiplier for angleIncrement below
    const angleIncrement = isSelected ? -(Math.PI * 0.02) : Math.PI * 0.04

    function animate() {
      // If not selected and angle is back to start, stop the animation
      if (!isSelected && angleRef.current >= startingAngleRadians) {
        // Reset to starting position
        angleRef.current = startingAngleRadians
        setStartingPosition()
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current)
          animationRef.current = null
        }
        // Exit the animate loop
        return
      }

      // Convert the angle to a range of 0 to 2 * Math.PI for consistency
      if (angleRef.current <= startingAngleRadians - 2 * Math.PI) {
        angleRef.current += 2 * Math.PI
      }

      // Continue with animation
      setCircleTopLeft(angleRef.current, star1Ref.current)
      setCircleTopLeft(angleRef.current + Math.PI, star2Ref.current)
      angleRef.current += angleIncrement // Increment angle for the next frame
      animationRef.current = requestAnimationFrame(animate)
    }

    // Start animation when isSelected changes
    animate()

    return () => {
      // Cleanup by cancelling any ongoing animation frame when the component unmounts or isSelected changes
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
        animationRef.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected])

  return (
    <Box
      opacity={allImagesLoaded ? 1 : 0}
      position="absolute"
      width="50%"
      bottom={0}
      left="50%"
      transform="translateX(-50%) translateY(30%)"
      cursor="pointer"
    >
      <Image src={GoodYearSticker} w="100%" zIndex={0} onLoad={() => setStickerLoaded(true)} />
      <Image src={GoodYearText} position="absolute" top="16%" w="91%" left="4.5%" zIndex={4} onLoad={() => setTextLoaded(true)} />
      <Image src={GoodYearEllipse} position="absolute" w="46.2%" top="37%" left="17.75%" zIndex={1} onLoad={() => setEllipseLoaded(true)} />
      <Box position="absolute" top="45.5%" left="19%" w="43.2%" zIndex={3} transform="rotate(-15.18deg)">
        <Box
          ref={ellipseRef}
          width="full"
          css={{ aspectRatio: 80 / 29 }}
          // Uncomment to debug ellipse placement
          // borderWidth="4px"
          // borderColor="rgba(255,99,71,0.4)"
          borderRadius="50%"
          position="relative"
        >
          <Box ref={star1Ref} w="20%" css={{ aspectRatio: 1 }} position="absolute" transform="rotate(15.18deg)">
            <Image src={GoodYearStar} height="full" marginX="auto" onLoad={() => setStarLoaded(true)} />
          </Box>
          <Box ref={star2Ref} w="20%" css={{ aspectRatio: 1 }} position="absolute" transform="rotate(15.18deg) translateY(-3%)">
            <Image src={GoodYearStar} height="full" marginX="auto" onLoad={() => setStarLoaded(true)} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const VioletCrumbleHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const translateYValue = useBreakpointValue({ md: "10px", lg: "20px" })
  const transition = { duration: 0.3, ease: "easeOut" }
  return (
    <Box position="absolute" bottom={{ md: "-18px", lg: "-2px" }} left="0" right="0" marginX="auto">
      <MotionBox
        position="absolute"
        bottom={{ md: "20px", lg: "16px" }}
        left={{ md: "24%", lg: "22%", xl: "26%", gridXl: "28%", "2xl": "27%" }}
        zIndex={102}
        transformOrigin="center left"
        initial={{ rotate: 20 }}
        animate={
          isSelected
            ? {
                rotate: 0,
                transition: {
                  ...transition,
                },
              }
            : {
                rotate: 20,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <Image src={VC_LEFT} width={{ md: "70%", lg: "88%", xl: "90%", gridXl: "100%" }} maxWidth="108px" />
      </MotionBox>

      <MotionBox
        position="absolute"
        bottom="100px"
        left={{ md: "43%", lg: "45%" }}
        zIndex={102}
        initial={{ rotate: -7, scale: 0 }}
        animate={
          isSelected
            ? {
                rotate: 55,
                scale: 1,
                translateY: `-${translateYValue}`,
                transition: {
                  ...transition,
                },
              }
            : {
                rotate: -7,
                scale: 0,
                translateY: translateYValue,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <Image src={VC_SM_LEFT} width={{ md: "70%", lg: "100%" }} maxWidth="22px" />
      </MotionBox>

      <MotionBox
        position="absolute"
        bottom={{ md: "-25px", lg: "-50px" }}
        left="0"
        right="0"
        marginX="auto"
        zIndex={103}
        initial={{ translateY: 0 }}
        animate={
          isSelected
            ? {
                translateY: "-10px",
                transition: {
                  ...transition,
                },
              }
            : {
                translateY: 0,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <Image src={VC_TEXT} marginX="auto" width={{ md: "60%", lg: "65%", xl: "100%" }} maxWidth="260px" />
      </MotionBox>

      <MotionBox
        position="absolute"
        bottom="100px"
        right={{ base: "42%", lg: "44%", "2xl": "46%" }}
        zIndex={102}
        initial={{ rotate: -7, scale: 0 }}
        animate={
          isSelected
            ? {
                rotate: 90,
                scale: 1,
                translateY: `-${translateYValue}`,
                transition: {
                  ...transition,
                },
              }
            : {
                rotate: -7,
                scale: 0,
                translateY: translateYValue,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <Image src={VC_SM_RIGHT} width={{ md: "70%", lg: "100%" }} maxWidth="16px" />
      </MotionBox>

      <MotionBox
        position="absolute"
        bottom={{ md: "10px", lg: "0" }}
        right={{ md: "13%", lg: "21%", xl: "26%", gridXl: "28%", "2xl": "30%" }}
        zIndex={102}
        transformOrigin="center right"
        initial={{ rotate: 0 }}
        animate={
          isSelected
            ? {
                rotate: 18,
                transition: {
                  ...transition,
                },
              }
            : {
                rotate: 0,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <Image src={VC_RIGHT} width={{ md: "75%", lg: "88%", xl: "90%", gridXl: "100%" }} maxWidth="108px" />
      </MotionBox>
    </Box>
  )
}

const BreakfastClubHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const transition = { duration: 0.3, ease: "easeOut" }
  const { isMedium } = useMedia()
  return (
    <Box position="absolute" bottom={{ md: "-18px", lg: "-2px" }} marginX="auto" left="0" right="0">
      <MotionBox
        position="absolute"
        bottom={{ md: "-2px", lg: "-20px" }}
        left="0"
        width="100%"
        animate={
          isSelected
            ? {
                scale: 1.1,
                transition: {
                  ...transition,
                },
              }
            : {
                scale: 1,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <Image src={BC_BOWL} width={{ md: "80%", lg: "100%" }} maxWidth="279px" marginX="auto" />
      </MotionBox>
      <MotionBox
        position="absolute"
        bottom={{ md: "18px", lg: "6px" }}
        left="0"
        width="100%"
        animate={
          isSelected
            ? {
                scale: 1.2,
                translateY: "-6px",
                transition: {
                  ...transition,
                },
              }
            : {
                translateY: "6px",
                scale: 1,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <svg
          viewBox={BC_SPLASH.viewBox}
          width={isMedium ? "80%" : "100%"}
          style={{
            maxWidth: "250px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <use xlinkHref={BC_SPLASH.url} />
        </svg>
      </MotionBox>
      <Box position="absolute" bottom={{ md: "-25px", lg: "-50px" }} left="50%" transform="translateX(-50%)">
        <svg
          viewBox={BC_TEXT.viewBox}
          width="100%"
          style={{
            maxWidth: "212px",
          }}
        >
          <use xlinkHref={BC_TEXT.url} />
        </svg>
      </Box>
    </Box>
  )
}

const FairyBreadHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const transition = { duration: 0.3, ease: "easeOut" }

  return (
    <Box position="absolute" top="0" height="100%" marginX="auto" left="0" width="100%">
      <Box position="absolute" top="0" left="0" height="100%" width="100%" overflow="hidden" borderRadius="8px">
        <MotionBox
          position="absolute"
          left="-5%"
          top="0"
          width="110%"
          initial={{ translateY: "-100%" }}
          animate={
            isSelected
              ? {
                  translateY: "-61.5%",
                  transition: {
                    ...transition,
                  },
                }
              : {
                  translateY: "-100%",
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={FAIR_BREAD_SPRINKLES} width="100%" />
        </MotionBox>

        <MotionBox
          position="absolute"
          left="-5%"
          width="110%"
          bottom="0"
          initial={{ translateY: "100%" }}
          animate={
            isSelected
              ? {
                  translateY: "61.5%",
                  transition: {
                    ...transition,
                  },
                }
              : {
                  translateY: "100%",
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={FAIR_BREAD_SPRINKLES} width="100%" />
        </MotionBox>
      </Box>

      <Box
        position="absolute"
        bottom={{ md: "-22px", lg: "-36px" }}
        left="50%"
        transform="translateX(-50%)"
        width={{ md: "200px", lg: "300px" }}
      >
        <svg viewBox={FAIRY_BREAD_LOGO.viewBox} width="100%">
          <use xlinkHref={FAIRY_BREAD_LOGO.url} />
        </svg>
      </Box>
    </Box>
  )
}

const TropicalCoconutHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const transition = { duration: 0.3 }

  return (
    <Box position="absolute" top="0" height="100%" marginX="auto" left="0" width="100%">
      <Box position="absolute" top="0" left="0" height="100%" width="100%" overflow="hidden" borderRadius="8px">
        <MotionBox
          position="absolute"
          left="0"
          top="0"
          width="100%"
          initial={{ opacity: 0 }}
          animate={
            isSelected
              ? {
                  opacity: 1,
                  transition: {
                    ...transition,
                  },
                }
              : {
                  opacity: 0,
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={TROPICO_RAIN_DROPS} width="100%" />
        </MotionBox>
      </Box>

      <MotionBox
        position="absolute"
        bottom="-13%"
        width="100%"
        initial={{ scale: 0.85 }}
        animate={
          isSelected
            ? {
                scale: 1,
                transition: {
                  ...transition,
                },
              }
            : {
                scale: 0.85,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <svg viewBox={TROPICO_LOGO.viewBox} width="100%">
          <use xlinkHref={TROPICO_LOGO.url} />
        </svg>
      </MotionBox>
    </Box>
  )
}

const MallowHoverIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const transition = { duration: 0.3, ease: "easeOut" }

  return (
    <Box position="absolute" top="0" height="100%" marginX="auto" left="0" width="100%">
      <Box position="absolute" top="0" left="0" height="100%" width="100%" overflow="hidden" borderRadius="8px">
        <MotionBox
          position="absolute"
          left="0"
          top="0"
          width="100%"
          initial={{ translateY: "-5%" }}
          animate={
            isSelected
              ? {
                  translateY: "0%",
                  transition: {
                    ...transition,
                  },
                }
              : {
                  translateY: "-5%",
                  transition: {
                    ...transition,
                  },
                }
          }
        >
          <Image src={MALLOWS_FALLING} width="100%" />
        </MotionBox>
      </Box>

      <MotionBox
        position="absolute"
        bottom="-15%"
        width="100%"
        initial={{ scale: 0.45 }}
        animate={
          isSelected
            ? {
                scale: 0.55,
                transition: {
                  ...transition,
                },
              }
            : {
                scale: 0.45,
                transition: {
                  ...transition,
                },
              }
        }
      >
        <svg viewBox={MALLOW.viewBox} width="100%">
          <use xlinkHref={MALLOW.url} />
        </svg>
      </MotionBox>
    </Box>
  )
}

export default React.memo(NavigationHeaderMegaLevel2Featured)
